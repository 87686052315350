import { useState } from "react"
import styled from "styled-components"

import { TextField } from "@material-ui/core"

import { MDialog, TMDialogProps } from "src/ui/Dialog/MDialog"
import { spacing } from "src/ui/spacing"

export function ParadiseConfirmationDialog({
  title,
  open,
  onClose,
  onConfirm,
  content,
  confirmationText,
  confirmLabel,
  loading,
  ...props
}: {
  title: string
  open: boolean
  onClose: () => void
  onConfirm: () => void
  content: React.ReactNode
  confirmationText: string
  confirmLabel: string
} & TMDialogProps) {
  const [input, setInput] = useState("")

  return (
    <MDialog
      title={title}
      open={open}
      onClose={onClose}
      confirmLabel={confirmLabel}
      onConfirm={onConfirm}
      confirmButtonProps={{
        color: "destructive",
        loading: loading,
        disabled: input !== confirmationText,
      }}
      {...props}
    >
      <DialogWrapper>
        {content}
        <TextField
          fullWidth
          placeholder={confirmationText}
          onChange={(e) => setInput(e.target.value)}
        />
      </DialogWrapper>
    </MDialog>
  )
}

const DialogWrapper = styled.div`
  display: grid;
  gap: ${spacing.M};
`
